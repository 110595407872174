import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BrowserStorageService, storageKeys } from 'core/browser-storage.service';
import { Observable, catchError } from 'rxjs';
import { AppState, errorActions } from 'store';
import { environment } from '../environments/environment';

const timeOffset = String(new Date().getTimezoneOffset() * -1);

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    constructor(
        private readonly storage: BrowserStorageService,
        private readonly store: Store<AppState>,
    ) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const lang = this.storage.local.getItem(storageKeys.lang) || 'en';

        let headers = req.headers.set('Accept-Language', lang);
        headers = headers.set('clienttimeoffset', timeOffset);
        let url = req.url;
        if (req.url.startsWith('/api')) {
            url = `/api/${environment.apiVersion}/${req.url.substring(5)}`;
        }
        req = req.clone({ headers, url });

        return next.handle(req).pipe(
            catchError((err) => {
                if (err instanceof HttpErrorResponse && err.status === 500) {
                    this.store.dispatch(errorActions.apiError(err));
                } else if (err instanceof HttpErrorResponse && err.status === 403) {
                    this.store.dispatch(
                        errorActions.apiForbiddenError({
                            error: {
                                detail: 'Forbidden',
                                status: err.status,
                                title: 'Forbidden',
                                traceId: null,
                                type: 'Forbidden',
                            },
                        }),
                    );
                } else if (err instanceof HttpErrorResponse && err.status === 400) {
                    this.store.dispatch(
                        errorActions.apiBadRequestError({
                            error: {
                                error: err.error as Record<string, Array<string>>,
                                detail: err.statusText,
                                status: err.status,
                                title: err.name,
                                traceId: null,
                                type: 'Bad Request',
                            },
                        }),
                    );
                }
                throw err;
            }),
        );
    }
}
